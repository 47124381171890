#scheduler .mat-form-field-underline {
  display: none;
}

#scheduler .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #fafdfc;
  border: 0.0625rem solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem !important;
  padding: 0 0.75em 0 0.75em !important;
}

#analyticscss .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(32, 80, 114, 1) !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}
#analytics-2 .mat-form-field {
  width: 100%;
}
#analyticscss .mat-select-min-line {
  color: rgba(255, 255, 255, 1) !important;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  position: relative;
  top: -4px;
}

#analyticscss .mat-select-arrow-wrapper .mat-select-arrow {
  color: white;
}

#analytics-2 .mat-form-field-appearance-fill .mat-form-field-flex {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

#analytics-3 .mat-form-field-appearance-fill .mat-form-field-flex {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}
#analytics-3 .mat-datepicker-toggle {
  position: absolute;
  right: 0px;
  top: -10px;
}
#analytics-3 .mat-form-field-underline {
  display: none;
}
#analytics-2 .vertical-dropdown {
  position: absolute !important;
  top: 1.5rem !important;
  max-height: 12rem !important;
}

#analytics-2 .mat-form-field-underline {
  display: none;
}

#carousel-props .carousel-control-prev {
  left: -10px;
  top: -15px;
  opacity: 0;
}

#carousel-props .carousel-control-prev:hover {
  opacity: 0;
}

#carousel-props .carousel-control-next {
  right: -15px;
  top: -15px;
  opacity: 0;
}

#carousel-props .carousel-control-next:hover {
  opacity: 0;
}
