#delegateDashboard #select .mat-sort-header-container {
  display: flex;
  justify-content: center;
}

#delegateDashboard #points .mat-sort-header-container {
  margin-left: 20%;
  display: flex;
  justify-content: center;
}

#delegateDashboard .mat-checkbox-ripple .mat-ripple-element {
  background-color: #205072 !important;
}

#delegateDashboard .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #205072 !important;
}

#delegateDashboard .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #205072 !important;
}

#delegateDashboard .mat-sort-header-container {
  justify-content: center;
}


#delegateDashboard .mat-tab-body-content {
  overflow: hidden;
}

@media only screen and (max-width:62.25rem) {
  #delegateDashboard .mat-tab-body-content {
    overflow: auto;
  }
}