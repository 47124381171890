#expansion .mat-expansion-indicator {
    margin-left:6px;
   }
#expansion  mat-expansion-panel-header {
     height: auto;
   }

#pto .pasq{
  max-width: -webkit-fill-available;
    margin-top: -14px;
    margin-left: 6px;

}