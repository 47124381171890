#recommend-form .mat-form-field-underline {
  display: none;
}

#recommend-form .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #fafdfc;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px !important;
  padding: 0 0.75em 0 0.75em !important;
}

#recommend-form .mat-select-panel-wrap {
  position: absolute;
  top: 0.525rem !important;
}

#recommend-form .mat-select-panel {
  overflow-x: hidden !important;
  margin-top: 2rem;
  margin-left: 0.3125rem;
  min-width: calc(100% + 1.4375rem) !important;
  box-shadow: 0px 1px 4px 1px gray !important;
  border-radius: 0 0 7px 7px !important;
}

#recommend-form .mat-select-search-inside-mat-option .mat-select-search-input {
  box-shadow: 0px 1px 4px 1px gray !important;
  border-radius: 7px !important;
}

#recommend-form .mat-horizontal-content-container {
  padding: 0 15px 0px 15px;
}

#recommend-form .mat-horizontal-stepper-header-container {
  display: none !important;
}

#recommend-form .mat-dialog-container {
  overflow: unset;
}

#recommend-form .mat-radio-label {
  margin-top: 6px;
}


#recommend-form .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #38a3a5;
}

#recommend-form .mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent.mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #38a3a5;
}

#recommend-form .mat-dropdown-styling {
  height: 300px;
  margin-left: 35px;
  min-width: 250px !important;
}


#recommend-dashboard .mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: white !important;
  height: 65px !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

#recommend-date .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  color: rgba(0, 0, 0, .12);
}

#recommend-date .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, .12);
}

#recommend-table .mat-expansion-indicator {
  position: absolute;
  right: 5.2rem;
}

#recommend-table .mat-expansion-panel-header {
  height: auto;
}

#main-checkbox input[type="checkbox"] {
  width: 20px;

}

#required-checkbox input[type="checkbox"] {
  width: 20px;
  height: 15px;
}

.nomination-filter-panel {
  position: absolute !important;
  top: 1.5rem !important;
  max-height: 12rem !important;
}

.award-Category-panel {
  position: absolute !important;
  top: 22px !important;
  max-height: 13rem !important;
}

@media (min-width:769px) and (max-width:1200px) {
  #recommend-table .mat-expansion-indicator {
    position: absolute;
    right: 57px;
  }
}

#unrecognized-talent .even-index {
  background: rgba(255, 255, 255, 1);
}

#unrecognized-talent .div-properties {
  background: rgba(255, 255, 255, 1);
}

#unrecognized-talent .odd-index {
  background: rgba(252, 252, 253, 1);

}


#unrecognized-talent .req-brd {
  background: rgba(255, 255, 255, 1);
  padding: 8px;
  border-radius: 12px;
}

#unrecognized-talent .table-heading {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 0, 0, 1);

}

/* .even-index {
  background: rgba(255, 255, 255, 1);
}


.odd-index {
  background: rgba(252, 252, 253, 1);

}


.req-brd {
  background: rgba(255, 255, 255, 1);
  padding: 8px;
  border-radius: 12px;
} */
#unrecognized-talent .emp-name {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;

}