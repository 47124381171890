#mobile-view .rotate-expansion {
    transform: rotate(180deg);
    transition-duration: 0.1s;
    transition-timing-function: ease-in;
  }
  #mobile-view .title{
    font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color:rgba(122, 122, 122, 1)
  
  }
  #mobile-view .sub-title{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(0, 0, 0, 1);
  
    
  }
  #mobile-view .arrow-properties{
    display: flex;
      justify-content: center;
      margin-top: 1rem;
  }
  #mobile-view .name-2{
    font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color:rgba(63, 63, 63, 1)
  }
  #mobile-view  .designation{
    font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(122, 122, 122, 1);
  
  }
  
  #mobile-view  mat-card{
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  border-radius:16px;
  }