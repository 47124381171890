#nomineeDraft .mat-form-field-underline {
  display: none;
}

#nomineeDraft .mat-expansion-panel-body {
  padding: 0 !important;
}

#nomineeDraft .mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: white !important;
  height: 3.75rem !important;
  box-shadow: 0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  border-radius: 0.75rem;
}

#nomineeDraft
  .mat-form-field-appearance-outline.mat-focused
  .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}

#nomineeDraft .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}

#nomineeDraft .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0;
  margin-top: 0.75rem;
}

#nomineeDetails .mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: white !important;
  height: 2.8125rem !important;
  box-shadow: 0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  border-radius: 0.75rem;
}

#nomineeDetails .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0;
  margin-top: 0.25rem;
}

#nomineeDetails
  .mat-form-field-appearance-outline.mat-focused
  .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}

#nomineeDetails .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}

#nomineeDetails .mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: rgba(56, 163, 165, 1);
}

#awardNomination .mat-tab-label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
}

#awardNomination .mat-tab-label.mat-tab-label-active {
  color: rgba(56, 163, 165, 1) !important;
  opacity: 1 !important;
}

#awardNomination .mat-ink-bar {
  border-bottom: 0.1875rem solid #38a3a5;
}

#awardNomination .mat-tab-header {
  border-bottom: none;
}

#nomineeByMe .mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: white !important;
  height: 3.75rem !important;
  box-shadow: 0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  border-radius: 0.75rem;
}

#nomineeByMe
  .mat-form-field-appearance-outline.mat-focused
  .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}

#nomineeByMe .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}

#nomineeByMe .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0;
  margin-top: 0.75rem;
}

#nomineeByMe .mat-expansion-panel-body {
  padding: 0 !important;
}

#reviewRecommendation .mat-form-field-underline {
  display: none;
}

#reviewRecommendation .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(250, 251, 255, 1);
  border: 0.0625rem solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem !important;
  padding: 0.313rem 0.75em 0 0.75em !important;
}

#reviewRecommendation .mat-form-field-underline {
  display: none;
}

#reviewRecommendation .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0;
  margin-top: 0.3125rem;
}

#reviewRecommendation
  .mat-form-field-appearance-outline
  .mat-form-field-outline {
  background-color: white !important;
  height: 2.9375rem !important;
  box-shadow: 0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  border-radius: 0.75rem;
}

#reviewRecommendation
  .mat-form-field-appearance-outline.mat-focused
  .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}

#reviewRecommendation
  .mat-form-field-appearance-outline
  .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}

#nomineeDetails .mat-horizontal-content-container {
  padding: 0 15px 20px 15px;
}

#nomineeDetails .mat-form-field-underline {
  display: none;
}

#mat-tab-properties .mat-tab-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #000000;
}

#mat-tab-properties .mat-tab-label.mat-tab-label-active {
  background-color: transparent;
  color: #205072;
}

#mat-tab-properties .mat-ink-bar {
  border-bottom: 3px solid #205072;
}

#award-winner .mat-form-field-underline {
  display: none;
}

#carousel-properties {
  .carousel-control-next {
    display: none !important;
  }

  .carousel-control-prev {
    display: none !important;
  }
}
