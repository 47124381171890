.mx-height {
  max-height: 450px;
  overflow: hidden;
  overflow-y: scroll;
}

.bulk_approve_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.875rem;
  width: 6.875rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  letter-spacing: 0.02em;
  color: #ffffff;
  background: linear-gradient(180deg, #83e79f 0%, #30c718 100%);
  border-radius: 0.25rem;
  border: none;
}

.bulk_reject_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.875rem;
  width: 6.875rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  letter-spacing: 0.02em;
  color: #ffffff;
  background: linear-gradient(180deg, #fe7d7d 0%, #ff4848 100%);
  border-radius: 0.25rem;
  border: none;
}

.delegate_btn {
  background: linear-gradient(180deg, #519acd 0%, #519acd 100%);
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.875rem;
  width: 6.875rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  letter-spacing: 0.02em;
  color: #ffffff;
  border: none;
}
