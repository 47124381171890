#certificate-form .mat-form-field-underline {
  display: none;
}

#certificate-form .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #fafdfc;
  border: 0.0625rem solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem !important;
  padding: 0 0.75em 0 0.75em !important;
}

#certificate-form
  .mat-radio-button.mat-accent.mat-radio-checked
  .mat-radio-outer-circle {
  border-color: #38a3a5;
}

#certificate-form .mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #38a3a5;
}

#certificate-view pdf-viewer.pdf-viewer-web {
  max-width: 90%;
  margin: auto;
  height: 12rem;
}
#certificate-view pdf-viewer.pdf-viewer-mobile {
  max-width: 90%;
  margin: auto;
  height: 12.375rem;
  width: 100%;
}

#cv pdf-viewer.pdf-viewer-web {
  margin: auto;
  height: 60vh;
}
#cv pdf-viewer.pdf-viewer-mobile {
  margin: auto;
  height: 60vh;
}

#interview-form .mat-form-field-underline {
  display: none;
}

#interview-form .mat-dropdown-styling {
  height: 18.75rem;
  margin-left: 2.1875rem;
  min-width: 15.625rem !important;
}
#interview-form .mat-option-text {
  width: 0rem !important;
}
#interview-form
  .mat-radio-button.mat-accent.mat-radio-checked
  .mat-radio-outer-circle {
  border-color: #38a3a5;
}

#interview-form .mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #38a3a5;
}

#interview-form .mat-dialog-container {
  overflow: unset;
}

#interview-form .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #fafdfc;
  border: 0.0625rem solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem !important;
  padding: 0 0.75em 0 0.75em !important;
}

#interview-form .mat-pseudo-checkbox-checked {
  background: #38a3a5 !important;
}
#interview-form
  .mat-primary
  .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #38a3a5 !important;
}

#feedback-form .mat-form-field-underline {
  display: none;
}

#feedback-form .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: white;
  border: 0.0625rem solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem !important;

  padding: 0 0.75em 0 0.75em !important;
}
#feedback-form .mat-dropdown-styling {
  height: 18.75rem;
  margin-left: 2.1875rem;
  min-width: 15.625rem !important;
}
#feedback-form
  .mat-radio-button.mat-accent.mat-radio-checked
  .mat-radio-outer-circle {
  border-color: #38a3a5;
}

#feedback-form .mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #38a3a5;
}

#feedback-form .certificationModal .mat-dialog-container {
  overflow: unset;
}
#feedback-form .mat-radio-label {
  margin-top: 0.375rem;
}

#feedback-form .mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked {
  background: #38a3a5 !important;
}

#my-contribution .mat-tab-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: #000000;
}
#my-contribution .mat-tab-label.mat-tab-label-active {
  background-color: transparent;
  color: #205072;
}

#my-contribution .mat-ink-bar {
  border-bottom: 3px solid #205072;
}

#my-contribution .remove-border-bottom .mat-tab-header {
  border-bottom: none;
}
