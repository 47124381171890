#dashboard .mat-drawer-inner-container {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

#heading-porperties button {
  background-color: transparent;
}

#heading-porperties button {
  background-color: transparent;
}

#heading-porperties .back-btn {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  max-height: 2.2rem;
  background: white;
  border: none;
}

#heading-porperties .first-heading {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

#team-history .mat-tab-body-content {
  height: 100%;
  overflow: hidden;
}
#team-history .mat-tab-label .mat-tab-label-content {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
}

#heading-porperties .first-heading {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.def-quarter-warn {
  color: rgb(161, 161, 161);
  font-size: 11px;
}
