/* @import url("https://fonts.googleapis.com/css?family=Poppins");
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap"; */
@import 'styles/components/dashboard.css';
@import 'styles/components/delegate-dashboard.css';
@import 'styles/components/header.css';
@import 'styles/components/categories.css';
@import 'styles/components/recommendation.css';
@import 'styles/components/award.css';
@import 'styles/components/mobile-tables.css';
@import 'styles/components/nominated-by-me.css';
@import 'styles/components/nomination-draft.css';
@import 'styles/components/emp-feeback.css';
@import 'styles/components/nomination-form.css';
@import 'styles/components/scheduler.css';
@import 'styles/components/redemption.css';
@import 'styles/components/delegate-contri-view.css';

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/poppins-3.woff2');
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/poppins-2.woff2');
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/poppins.woff2');
}

@font-face {
  font-family: 'Material Icons';
  src: url('assets/fonts/Material icons.woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.award-card-tooltip {
  background: black !important;
}

.note-font {
  font-size: 0.75rem;
}

/* body {
  background-image: url("./assets/images/contripoint-bg.svg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  font-family: "Poppins" !important;
} */
body {
  background-color: #f7f6fb;

  font-family: 'Poppins' !important;
}

.toast-container {
  position: fixed;
  top: 2rem;
}

.widthOne {
  width: 40%;
}

.widthTwo {
  width: 1%;
}

.widthThree {
  width: 59%;
}

.widthFour {
  width: 5%;
}

.widthFive {
  width: 95%;
}

.mat-list-option {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  color: black;
  font-size: 13px;
}

.disable_ripple:hover {
  background: white !important;
}

.pointer {
  cursor: pointer;
}

@media only screen and (max-width: 25rem) {
  .panel-class,
  .panel-form-class {
    overflow-x: hidden !important;
    margin-top: 2rem;
    margin-left: 1.875rem;
    min-width: 14.6875rem !important;
  }
}

@media only screen and (min-width: 25rem) {
  .panel-class,
  .panel-form-class {
    overflow-x: hidden !important;
    margin-top: 2rem;
    margin-left: 1.875rem;
    min-width: 17.8125rem !important;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .reviewRecommendation .my-select-panel-class {
    overflow-x: hidden !important;
    margin-top: 2rem;
    margin-left: 0.625rem;
    min-width: 14.6875rem !important;
  }
}

@media only screen and (min-width: 48rem) {
  .reviewRecommendation .my-select-panel-class {
    overflow-x: hidden !important;
    margin-top: 2rem;
    margin-left: 0.625rem;
    min-width: 17.8125rem !important;
  }
}

@media only screen and (max-width: 22.4375rem) {
  .panel-form-class {
    height: 15.625rem;
  }
}

@media (min-width: 576px) and (max-width: 725px) {
  .info {
    margin-left: -15px;
  }
}

@media only screen and (max-width: 359px) {
  mat-radio-button {
    font-size: 10px !important;
  }
}

.mClass .mat-dialog-container {
  padding: 0;
}

::ng-deep .mat-form-field-underline {
  display: none;
}

.mat-dialog-container {
  padding: 0 !important;
  border-radius: 12px !important;
  overflow: hidden !important;
}

.mat-menu-panel {
  min-width: 200px !important;
}

.my-dialog {
  position: relative !important;
}

.panel-class {
  overflow-x: hidden !important;
  margin-top: 2rem;
  margin-left: 1.875rem;
  min-width: 17.6875rem !important;
}

@media only screen and (max-width: 25rem) {
  .panel-class,
  .panel-form-class {
    overflow-x: hidden !important;
    margin-top: 2rem;
    margin-left: 1.875rem;
    min-width: 14.6875rem !important;
  }
}

.my-class {
  height: 25rem;
  min-width: 25.625rem !important;
  overflow-x: hidden !important;
}

@media only screen and (max-width: 22.375rem) {
  .my-class {
    height: 18.75rem !important;
    margin-left: -1.875rem;
  }
}

@media only screen and (min-width: 25rem) {
  .panel-class,
  .panel-form-class {
    overflow-x: hidden !important;
    margin-top: 2rem;
    margin-left: 1.875rem;
    min-width: 17.8125rem !important;
  }
}

@media only screen and (min-width: 48rem) {
  .panel-inter-class {
    overflow-x: hidden !important;
    margin-top: 2rem;
    margin-left: 0.625rem;
    min-width: 17.8125rem !important;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .panel-inter-class {
    overflow-x: hidden !important;
    margin-top: 2rem;
    margin-left: 0.625rem;
    min-width: 14.6875rem !important;
  }
}

@media only screen and (max-width: 22.4375rem) {
  .panel-form-class {
    height: 15.625rem;
  }
}

.banner-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.banner-subheading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #ffffff;
}

.on-close {
  position: relative;
  z-index: 1;
}

.no-record {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.no-record-sub-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.display-grid {
  display: grid;
}

.date {
  font-family: Poppins;
}

.space-between {
  justify-content: space-between;
}

.pending {
  background: #ffcc69;
}

.success {
  background: #17b44d;
}

.rejected {
  background: #ff6060;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #38a3a5 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: white !important;
  border: 1px solid #38a3a5;
}

.mat-slide-toggle-thumb {
  border: 1px solid lightgray;
}

.nomination .mat-form-field-wrapper {
  padding-bottom: 0 !important;
  margin: 0 !important;
}

.nom-filter .mat-checkbox-layout {
  margin: 0 !important;
}

.approve-nom-error .mat-form-field-subscript-wrapper {
  padding-left: 0px !important;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0 !important;
}

.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

.timepicker__header {
  background: linear-gradient(235.97deg, #7be495 -56.49%, #38a3a5 100%),
    linear-gradient(0deg, #d9d9d9, #d9d9d9) !important;
}

/* .clock-face__number{
  /* background-color: yellow !important; */

.timepicker-button {
  color: #38a3a5 !important;
  font-family: 'Poppins' !important;
  font-style: normal !important;
}

.transaction-table-year-filter .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.transaction-table-year-filter .mat-form-field-outline {
  background: #fafbff !important;
}
