#history .mat-sort-header-container {
    justify-content: center;
}

#redeem .mat-form-field-underline {
    display: none;
}

@media only screen and (min-width: 400px) {
    .redeem-panel {
        overflow-x: hidden !important;
        margin-top: 27px;
        margin-left: 7px;
        min-width: 200px !important;
    }
}

@media only screen and (max-width: 400px) {
    .redeem-panel {
        overflow-x: hidden !important;
        margin-top: 32px;
        margin-left: 30px;
        min-width: 235px !important;
    }
}

@media only screen and (min-width: 400px) {
 .title-dropdown {
    overflow-x: hidden !important;
    margin-top: 32px;
    margin-left: 7px;
    min-width: 243px !important;
  }
}


@media only screen and (min-width: 400px) {
   .award-dropdown {
    overflow-x: hidden !important;
    margin-top: 32px;
    margin-left: 7px;
    min-width: 320px !important;
  }
}

@media only screen and (max-width: 400px) {
 .award-dropdown {
    overflow-x: hidden !important;
    margin-top: 32px;
    margin-left: 7px;
    min-width: 235px !important;
  }
}
@media only screen and (min-width: 400px) {
 .award-dropdown2 {
    overflow-x: hidden !important;
    margin-top: 30px;
    margin-left: 7px;
    min-width: 275px !important;
  }
}

@media only screen and (max-width: 400px) {
  .award-dropdown2 {
    overflow-x: hidden !important;
    margin-top: 30px;
    margin-left: 10px;
    min-width: 250px !important;
  }
}