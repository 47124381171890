#header  .my-class {
    height: 25rem;
    min-width: 25.625rem !important;
    overflow-x: hidden !important;
  }
  @media only screen and (max-width:22.375rem) {
    #header .my-class {
        height: 18.75rem !important;
        margin-left: -1.875rem;
      }
  }
