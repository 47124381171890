#recommend-form .mat-form-field-underline {
  display: none;
}

#recommend-form .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #fafdfc;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px !important;
  padding: 0 0.75em 0 0.75em !important;
}

#recommend-form .mat-select-panel-wrap {
  position: absolute;
  top: 0.525rem !important;
}

#recommend-form .mat-select-panel {
  overflow-x: hidden !important;
  margin-top: 2rem;
  margin-left: 0.3125rem;
  min-width: calc(100% + 1.4375rem) !important;
  box-shadow: 0px 1px 4px 1px gray !important;
  border-radius: 0 0 7px 7px !important;
}

#recommend-form .mat-select-search-inside-mat-option .mat-select-search-input {
  box-shadow: 0px 1px 4px 1px gray !important;
  border-radius: 7px !important;
}

#recommend-form .mat-select-value-text {
  font-family: Poppins !important;
}

#recommend-form .mat-horizontal-content-container {
  padding: 0 15px 0px 15px;
}

#recommend-form .mat-horizontal-stepper-header-container {
  display: none !important;
}

#recommend-form .mat-dialog-container {
  overflow: unset;
}

#recommend-form .mat-radio-label {
  margin-top: 6px;
}


#recommend-form .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #38a3a5;
}

#recommend-form .mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent.mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #38a3a5;
}

#recommend-form .mat-dropdown-styling {
  height: 300px;
  margin-left: 35px;
  min-width: 250px !important;
}

#recommend-form .mat-select-search-inner {
  position: fixed !important;
}

#recommend-dashboard .mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: white !important;
  height: 65px !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

#recommend-date .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  color: rgba(0, 0, 0, .12);
}

#recommend-date .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, .12);
}

#assigned-winners .mat-form-field-underline {
  display: none;
}

#assigned-winners .mat-select-value-text {
  font-family: Poppins !important;
}